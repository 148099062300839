<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <small v-bind="attrs" v-on="on" :class="classes[color].textColor">
          <v-icon left :color="classes[color].color" small>
            mdi-package-variant-closed
          </v-icon>
          {{ $format.decimal(productCompany.available_stock) }}
        </small>
      </template>
      <span>Disponível</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    productCompany: {
      type: Object,
      required: true,
    },
  },

  data : () => ({
    classes : {
      negativeStock : {
        color : 'error',
        textColor : 'error--text',
      },
      stockZero : {
        color : '#BDBDBD',
        textColor : 'text--disabled',
      },
      lowOnStock : {
        color : 'warning',
        textColor : 'warning--text',
      },
      positiveStock : {
        color : 'success',
        textColor : 'success--text',
      }
    }
  }),

  computed: {
    color() {
      if (this.productCompany.available_stock < 0) {
        return "negativeStock";
      }

      if (this.productCompany.available_stock == 0) {
        return "stockZero";
      }

      if (this.productCompany.available_stock > this.productCompany.minimum_stock) {
        return "positiveStock";
      }

      if (this.productCompany.available_stock <= this.productCompany.minimum_stock) {
        return "lowOnStock";
      }

      return "stockZero"
    },
  },
};
</script>
